import { j as a } from "./jsx-runtime-D0AQ1Mn7.js";
import { T as l } from "./Text-CwTWgi3Y.js";
import { T as r } from "./Tooltip-B25WPLZY.js";
import { c as d } from "./utils-CJ9afRe1.js";
const w = ({
  className: x,
  rounded: f,
  items: t,
  showLegend: m,
  pieBar: i,
  height: o,
  progressBackGroundColor: n,
  showSeparator: u,
  ...h
}) => {
  const c = [...t].sort((e, s) => ((e == null ? void 0 : e.legendOrder) ?? 0) - ((s == null ? void 0 : s.legendOrder) ?? 0));
  return /* @__PURE__ */ a.jsx(r.Provider, { children: /* @__PURE__ */ a.jsxs(r, { children: [
    /* @__PURE__ */ a.jsx(r.Trigger, { asChild: !0, children: /* @__PURE__ */ a.jsxs(
      "div",
      {
        role: "progressbar",
        "aria-label": "Progress bar",
        className: `${x}`,
        ...h,
        children: [
          m && /* @__PURE__ */ a.jsx("div", { className: "flex gap-1 md:gap-4 xl:gap-10", children: c.map((e) => /* @__PURE__ */ a.jsxs(
            "div",
            {
              className: "inline-flex space-x-1 items-center truncate text-ellipsis",
              title: `${e.title} - ${e.value}%`,
              children: [
                /* @__PURE__ */ a.jsx(
                  "span",
                  {
                    className: "rounded-full w-2 h-2 sm:h-3 sm:w-3 shrink-0",
                    style: { backgroundColor: e.color }
                  }
                ),
                /* @__PURE__ */ a.jsx(l, { className: "text-xs text-black-primary ", children: e.title }),
                /* @__PURE__ */ a.jsxs(l, { className: "text-xs text-gray-neutral-100 hidden sm:inline-flex", children: [
                  e.value,
                  "%"
                ] })
              ]
            },
            e.title
          )) }),
          /* @__PURE__ */ a.jsx("div", { className: d(
            "relative inline-flex w-full overflow-hidden",
            {
              "rounded-md": f,
              "h-2": !o,
              "bg-black-10": !n
            },
            n,
            o
          ), children: t.filter((e) => !e.excludeFromProgress).map((e) => /* @__PURE__ */ a.jsx(
            "div",
            {
              "data-active": u && e.value < 100,
              className: d(
                `h-full bg-primary transition-all flex-nowrap left-0 top-0 
              after:content-[' '] after:bg-white after:absolute after:right-0 after:top-0 after:h-full`,
                e.className,
                {
                  "absolute rounded-md data-[active=true]:first:after:w-0 after:-ml-1 data-[active=true]:after:w-0.5": !i,
                  "relative data-[active=true]:last:after:w-0 after:-ml-1 data-[active=true]:after:w-0.5": i
                }
              ),
              style: {
                width: `${e.value}%`,
                ...e.color ? {
                  backgroundColor: e.color
                } : {}
              }
            },
            e.title
          )) })
        ]
      }
    ) }),
    /* @__PURE__ */ a.jsx(
      r.Content,
      {
        align: "start",
        side: "bottom",
        className: "flex flex-col gap-2",
        alignOffset: 0,
        children: c.map((e) => /* @__PURE__ */ a.jsxs("div", { className: "flex gap-2", children: [
          /* @__PURE__ */ a.jsx(
            "div",
            {
              className: "rounded-full w-2 h-2 sm:h-3 sm:w-3 shrink-0",
              style: { backgroundColor: e.color }
            }
          ),
          /* @__PURE__ */ a.jsx(l, { className: " text-black-primary ", size: "xs", weight: "normal", children: e.title }),
          /* @__PURE__ */ a.jsxs(l, { className: " text-gray-neutral-70", size: "xs", weight: "normal", children: [
            e.value,
            "%"
          ] })
        ] }, e.title))
      }
    )
  ] }) });
};
export {
  w as P
};
