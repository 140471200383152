import { j as y } from "./jsx-runtime-D0AQ1Mn7.js";
import { h as Me, b as De, a as _e, d as Le } from "./index-BftHgWg_.js";
import * as l from "react";
import { $ as I } from "./index-BEhJEO-h.js";
import { B as Ke } from "./Button-CAmI0_H8.js";
import { D as pe } from "./Dialog.component-fr4P9TXE.js";
import { I as Fe } from "./Icon-vWfhOtJ0.js";
import { c as _ } from "./utils-CJ9afRe1.js";
var he = 1, qe = 0.9, Pe = 0.8, Ve = 0.17, Y = 0.1, Q = 0.999, Ge = 0.9999, He = 0.99, ze = /[\\\/_+.#"@\[\(\{&]/, Be = /[\\\/_+.#"@\[\(\{&]/g, Oe = /[\s-]/, be = /[\s-]/g;
function ee(e, n, t, s, u, d, c) {
  if (d === n.length) return u === e.length ? he : He;
  var p = `${u},${d}`;
  if (c[p] !== void 0) return c[p];
  for (var v = s.charAt(d), m = t.indexOf(v, u), o = 0, h, R, E, C; m >= 0; ) h = ee(e, n, t, s, m + 1, d + 1, c), h > o && (m === u ? h *= he : ze.test(e.charAt(m - 1)) ? (h *= Pe, E = e.slice(u, m - 1).match(Be), E && u > 0 && (h *= Math.pow(Q, E.length))) : Oe.test(e.charAt(m - 1)) ? (h *= qe, C = e.slice(u, m - 1).match(be), C && u > 0 && (h *= Math.pow(Q, C.length))) : (h *= Ve, u > 0 && (h *= Math.pow(Q, m - u))), e.charAt(m) !== n.charAt(d) && (h *= Ge)), (h < Y && t.charAt(m - 1) === s.charAt(d + 1) || s.charAt(d + 1) === s.charAt(d) && t.charAt(m - 1) !== s.charAt(d)) && (R = ee(e, n, t, s, m + 1, d + 2, c), R * Y > h && (h = R * Y)), h > o && (o = h), m = t.indexOf(v, m + 1);
  return c[p] = o, o;
}
function ge(e) {
  return e.toLowerCase().replace(be, " ");
}
function Ue(e, n, t) {
  return e = t && t.length > 0 ? `${e + " " + t.join(" ")}` : e, ee(e, n, ge(e), ge(n), 0, 0, {});
}
var q = '[cmdk-group=""]', Z = '[cmdk-group-items=""]', We = '[cmdk-group-heading=""]', re = '[cmdk-item=""]', ve = `${re}:not([aria-disabled="true"])`, te = "cmdk-item-select", A = "data-value", Te = (e, n, t) => Ue(e, n, t), xe = l.createContext(void 0), V = () => l.useContext(xe), ye = l.createContext(void 0), ne = () => l.useContext(ye), we = l.createContext(void 0), ke = l.forwardRef((e, n) => {
  let t = L(() => {
    var r, i;
    return { search: "", value: (i = (r = e.value) != null ? r : e.defaultValue) != null ? i : "", filtered: { count: 0, items: /* @__PURE__ */ new Map(), groups: /* @__PURE__ */ new Set() } };
  }), s = L(() => /* @__PURE__ */ new Set()), u = L(() => /* @__PURE__ */ new Map()), d = L(() => /* @__PURE__ */ new Map()), c = L(() => /* @__PURE__ */ new Set()), p = Ee(e), { label: v, children: m, value: o, onValueChange: h, filter: R, shouldFilter: E, loop: C, disablePointerSelection: G = !1, vimBindings: $ = !0, ...H } = e, ae = l.useId(), ue = l.useId(), ie = l.useId(), N = l.useRef(null), b = at();
  M(() => {
    if (o !== void 0) {
      let r = o.trim();
      t.current.value = r, k.emit();
    }
  }, [o]), M(() => {
    b(6, oe);
  }, []);
  let k = l.useMemo(() => ({ subscribe: (r) => (c.current.add(r), () => c.current.delete(r)), snapshot: () => t.current, setState: (r, i, f) => {
    var a, g, x;
    if (!Object.is(t.current[r], i)) {
      if (t.current[r] = i, r === "search") T(), U(), b(1, W);
      else if (r === "value" && (f || b(5, oe), ((a = p.current) == null ? void 0 : a.value) !== void 0)) {
        let S = i ?? "";
        (x = (g = p.current).onValueChange) == null || x.call(g, S);
        return;
      }
      k.emit();
    }
  }, emit: () => {
    c.current.forEach((r) => r());
  } }), []), O = l.useMemo(() => ({ value: (r, i, f) => {
    var a;
    i !== ((a = d.current.get(r)) == null ? void 0 : a.value) && (d.current.set(r, { value: i, keywords: f }), t.current.filtered.items.set(r, de(i, f)), b(2, () => {
      U(), k.emit();
    }));
  }, item: (r, i) => (s.current.add(r), i && (u.current.has(i) ? u.current.get(i).add(r) : u.current.set(i, /* @__PURE__ */ new Set([r]))), b(3, () => {
    T(), U(), t.current.value || W(), k.emit();
  }), () => {
    d.current.delete(r), s.current.delete(r), t.current.filtered.items.delete(r);
    let f = K();
    b(4, () => {
      T(), (f == null ? void 0 : f.getAttribute("id")) === r && W(), k.emit();
    });
  }), group: (r) => (u.current.has(r) || u.current.set(r, /* @__PURE__ */ new Set()), () => {
    d.current.delete(r), u.current.delete(r);
  }), filter: () => p.current.shouldFilter, label: v || e["aria-label"], disablePointerSelection: G, listId: ae, inputId: ie, labelId: ue, listInnerRef: N }), []);
  function de(r, i) {
    var f, a;
    let g = (a = (f = p.current) == null ? void 0 : f.filter) != null ? a : Te;
    return r ? g(r, t.current.search, i) : 0;
  }
  function U() {
    if (!t.current.search || p.current.shouldFilter === !1) return;
    let r = t.current.filtered.items, i = [];
    t.current.filtered.groups.forEach((a) => {
      let g = u.current.get(a), x = 0;
      g.forEach((S) => {
        let j = r.get(S);
        x = Math.max(j, x);
      }), i.push([a, x]);
    });
    let f = N.current;
    F().sort((a, g) => {
      var x, S;
      let j = a.getAttribute("id"), z = g.getAttribute("id");
      return ((x = r.get(z)) != null ? x : 0) - ((S = r.get(j)) != null ? S : 0);
    }).forEach((a) => {
      let g = a.closest(Z);
      g ? g.appendChild(a.parentElement === g ? a : a.closest(`${Z} > *`)) : f.appendChild(a.parentElement === f ? a : a.closest(`${Z} > *`));
    }), i.sort((a, g) => g[1] - a[1]).forEach((a) => {
      let g = N.current.querySelector(`${q}[${A}="${encodeURIComponent(a[0])}"]`);
      g == null || g.parentElement.appendChild(g);
    });
  }
  function W() {
    let r = F().find((f) => f.getAttribute("aria-disabled") !== "true"), i = r == null ? void 0 : r.getAttribute(A);
    k.setState("value", i || void 0);
  }
  function T() {
    var r, i, f, a;
    if (!t.current.search || p.current.shouldFilter === !1) {
      t.current.filtered.count = s.current.size;
      return;
    }
    t.current.filtered.groups = /* @__PURE__ */ new Set();
    let g = 0;
    for (let x of s.current) {
      let S = (i = (r = d.current.get(x)) == null ? void 0 : r.value) != null ? i : "", j = (a = (f = d.current.get(x)) == null ? void 0 : f.keywords) != null ? a : [], z = de(S, j);
      t.current.filtered.items.set(x, z), z > 0 && g++;
    }
    for (let [x, S] of u.current) for (let j of S) if (t.current.filtered.items.get(j) > 0) {
      t.current.filtered.groups.add(x);
      break;
    }
    t.current.filtered.count = g;
  }
  function oe() {
    var r, i, f;
    let a = K();
    a && (((r = a.parentElement) == null ? void 0 : r.firstChild) === a && ((f = (i = a.closest(q)) == null ? void 0 : i.querySelector(We)) == null || f.scrollIntoView({ block: "nearest" })), a.scrollIntoView({ block: "nearest" }));
  }
  function K() {
    var r;
    return (r = N.current) == null ? void 0 : r.querySelector(`${re}[aria-selected="true"]`);
  }
  function F() {
    var r;
    return Array.from((r = N.current) == null ? void 0 : r.querySelectorAll(ve));
  }
  function J(r) {
    let i = F()[r];
    i && k.setState("value", i.getAttribute(A));
  }
  function X(r) {
    var i;
    let f = K(), a = F(), g = a.findIndex((S) => S === f), x = a[g + r];
    (i = p.current) != null && i.loop && (x = g + r < 0 ? a[a.length - 1] : g + r === a.length ? a[0] : a[g + r]), x && k.setState("value", x.getAttribute(A));
  }
  function ce(r) {
    let i = K(), f = i == null ? void 0 : i.closest(q), a;
    for (; f && !a; ) f = r > 0 ? nt(f, q) : lt(f, q), a = f == null ? void 0 : f.querySelector(ve);
    a ? k.setState("value", a.getAttribute(A)) : X(r);
  }
  let se = () => J(F().length - 1), fe = (r) => {
    r.preventDefault(), r.metaKey ? se() : r.altKey ? ce(1) : X(1);
  }, me = (r) => {
    r.preventDefault(), r.metaKey ? J(0) : r.altKey ? ce(-1) : X(-1);
  };
  return l.createElement(I.div, { ref: n, tabIndex: -1, ...H, "cmdk-root": "", onKeyDown: (r) => {
    var i;
    if ((i = H.onKeyDown) == null || i.call(H, r), !r.defaultPrevented) switch (r.key) {
      case "n":
      case "j": {
        $ && r.ctrlKey && fe(r);
        break;
      }
      case "ArrowDown": {
        fe(r);
        break;
      }
      case "p":
      case "k": {
        $ && r.ctrlKey && me(r);
        break;
      }
      case "ArrowUp": {
        me(r);
        break;
      }
      case "Home": {
        r.preventDefault(), J(0);
        break;
      }
      case "End": {
        r.preventDefault(), se();
        break;
      }
      case "Enter":
        if (!r.nativeEvent.isComposing && r.keyCode !== 229) {
          r.preventDefault();
          let f = K();
          if (f) {
            let a = new Event(te);
            f.dispatchEvent(a);
          }
        }
    }
  } }, l.createElement("label", { "cmdk-label": "", htmlFor: O.inputId, id: O.labelId, style: it }, v), B(e, (r) => l.createElement(ye.Provider, { value: k }, l.createElement(xe.Provider, { value: O }, r))));
}), Je = l.forwardRef((e, n) => {
  var t, s;
  let u = l.useId(), d = l.useRef(null), c = l.useContext(we), p = V(), v = Ee(e), m = (s = (t = v.current) == null ? void 0 : t.forceMount) != null ? s : c == null ? void 0 : c.forceMount;
  M(() => {
    if (!m) return p.item(u, c == null ? void 0 : c.id);
  }, [m]);
  let o = Se(u, d, [e.value, e.children, d], e.keywords), h = ne(), R = D((b) => b.value && b.value === o.current), E = D((b) => m || p.filter() === !1 ? !0 : b.search ? b.filtered.items.get(u) > 0 : !0);
  l.useEffect(() => {
    let b = d.current;
    if (!(!b || e.disabled)) return b.addEventListener(te, C), () => b.removeEventListener(te, C);
  }, [E, e.onSelect, e.disabled]);
  function C() {
    var b, k;
    G(), (k = (b = v.current).onSelect) == null || k.call(b, o.current);
  }
  function G() {
    h.setState("value", o.current, !0);
  }
  if (!E) return null;
  let { disabled: $, value: H, onSelect: ae, forceMount: ue, keywords: ie, ...N } = e;
  return l.createElement(I.div, { ref: P([d, n]), ...N, id: u, "cmdk-item": "", role: "option", "aria-disabled": !!$, "aria-selected": !!R, "data-disabled": !!$, "data-selected": !!R, onPointerMove: $ || p.disablePointerSelection ? void 0 : G, onClick: $ ? void 0 : C }, e.children);
}), Xe = l.forwardRef((e, n) => {
  let { heading: t, children: s, forceMount: u, ...d } = e, c = l.useId(), p = l.useRef(null), v = l.useRef(null), m = l.useId(), o = V(), h = D((E) => u || o.filter() === !1 ? !0 : E.search ? E.filtered.groups.has(c) : !0);
  M(() => o.group(c), []), Se(c, p, [e.value, e.heading, v]);
  let R = l.useMemo(() => ({ id: c, forceMount: u }), [u]);
  return l.createElement(I.div, { ref: P([p, n]), ...d, "cmdk-group": "", role: "presentation", hidden: h ? void 0 : !0 }, t && l.createElement("div", { ref: v, "cmdk-group-heading": "", "aria-hidden": !0, id: m }, t), B(e, (E) => l.createElement("div", { "cmdk-group-items": "", role: "group", "aria-labelledby": t ? m : void 0 }, l.createElement(we.Provider, { value: R }, E))));
}), Ye = l.forwardRef((e, n) => {
  let { alwaysRender: t, ...s } = e, u = l.useRef(null), d = D((c) => !c.search);
  return !t && !d ? null : l.createElement(I.div, { ref: P([u, n]), ...s, "cmdk-separator": "", role: "separator" });
}), Qe = l.forwardRef((e, n) => {
  let { onValueChange: t, ...s } = e, u = e.value != null, d = ne(), c = D((o) => o.search), p = D((o) => o.value), v = V(), m = l.useMemo(() => {
    var o;
    let h = (o = v.listInnerRef.current) == null ? void 0 : o.querySelector(`${re}[${A}="${encodeURIComponent(p)}"]`);
    return h == null ? void 0 : h.getAttribute("id");
  }, []);
  return l.useEffect(() => {
    e.value != null && d.setState("search", e.value);
  }, [e.value]), l.createElement(I.input, { ref: n, ...s, "cmdk-input": "", autoComplete: "off", autoCorrect: "off", spellCheck: !1, "aria-autocomplete": "list", role: "combobox", "aria-expanded": !0, "aria-controls": v.listId, "aria-labelledby": v.labelId, "aria-activedescendant": m, id: v.inputId, type: "text", value: u ? e.value : c, onChange: (o) => {
    u || d.setState("search", o.target.value), t == null || t(o.target.value);
  } });
}), Ze = l.forwardRef((e, n) => {
  let { children: t, label: s = "Suggestions", ...u } = e, d = l.useRef(null), c = l.useRef(null), p = V();
  return l.useEffect(() => {
    if (c.current && d.current) {
      let v = c.current, m = d.current, o, h = new ResizeObserver(() => {
        o = requestAnimationFrame(() => {
          let R = v.offsetHeight;
          m.style.setProperty("--cmdk-list-height", R.toFixed(1) + "px");
        });
      });
      return h.observe(v), () => {
        cancelAnimationFrame(o), h.unobserve(v);
      };
    }
  }, []), l.createElement(I.div, { ref: P([d, n]), ...u, "cmdk-list": "", role: "listbox", "aria-label": s, id: p.listId }, B(e, (v) => l.createElement("div", { ref: P([c, p.listInnerRef]), "cmdk-list-sizer": "" }, v)));
}), et = l.forwardRef((e, n) => {
  let { open: t, onOpenChange: s, overlayClassName: u, contentClassName: d, container: c, ...p } = e;
  return l.createElement(Me, { open: t, onOpenChange: s }, l.createElement(De, { container: c }, l.createElement(_e, { "cmdk-overlay": "", className: u }), l.createElement(Le, { "aria-label": e.label, "cmdk-dialog": "", className: d }, l.createElement(ke, { ref: n, ...p }))));
}), tt = l.forwardRef((e, n) => D((t) => t.filtered.count === 0) ? l.createElement(I.div, { ref: n, ...e, "cmdk-empty": "", role: "presentation" }) : null), rt = l.forwardRef((e, n) => {
  let { progress: t, children: s, label: u = "Loading...", ...d } = e;
  return l.createElement(I.div, { ref: n, ...d, "cmdk-loading": "", role: "progressbar", "aria-valuenow": t, "aria-valuemin": 0, "aria-valuemax": 100, "aria-label": u }, B(e, (c) => l.createElement("div", { "aria-hidden": !0 }, c)));
}), w = Object.assign(ke, { List: Ze, Item: Je, Input: Qe, Group: Xe, Separator: Ye, Dialog: et, Empty: tt, Loading: rt });
function nt(e, n) {
  let t = e.nextElementSibling;
  for (; t; ) {
    if (t.matches(n)) return t;
    t = t.nextElementSibling;
  }
}
function lt(e, n) {
  let t = e.previousElementSibling;
  for (; t; ) {
    if (t.matches(n)) return t;
    t = t.previousElementSibling;
  }
}
function Ee(e) {
  let n = l.useRef(e);
  return M(() => {
    n.current = e;
  }), n;
}
var M = typeof window > "u" ? l.useEffect : l.useLayoutEffect;
function L(e) {
  let n = l.useRef();
  return n.current === void 0 && (n.current = e()), n;
}
function P(e) {
  return (n) => {
    e.forEach((t) => {
      typeof t == "function" ? t(n) : t != null && (t.current = n);
    });
  };
}
function D(e) {
  let n = ne(), t = () => e(n.snapshot());
  return l.useSyncExternalStore(n.subscribe, t, t);
}
function Se(e, n, t, s = []) {
  let u = l.useRef(), d = V();
  return M(() => {
    var c;
    let p = (() => {
      var m;
      for (let o of t) {
        if (typeof o == "string") return o.trim();
        if (typeof o == "object" && "current" in o) return o.current ? (m = o.current.textContent) == null ? void 0 : m.trim() : u.current;
      }
    })(), v = s.map((m) => m.trim());
    d.value(e, p, v), (c = n.current) == null || c.setAttribute(A, p), u.current = p;
  }), u;
}
var at = () => {
  let [e, n] = l.useState(), t = L(() => /* @__PURE__ */ new Map());
  return M(() => {
    t.current.forEach((s) => s()), t.current = /* @__PURE__ */ new Map();
  }, [e]), (s, u) => {
    t.current.set(s, u), n({});
  };
};
function ut(e) {
  let n = e.type;
  return typeof n == "function" ? n(e.props) : "render" in n ? n.render(e.props) : e;
}
function B({ asChild: e, children: n }, t) {
  return e && l.isValidElement(n) ? l.cloneElement(ut(n), { ref: n.ref }, t(n.props.children)) : t(n);
}
var it = { position: "absolute", width: "1px", height: "1px", padding: "0", margin: "-1px", overflow: "hidden", clip: "rect(0, 0, 0, 0)", whiteSpace: "nowrap", borderWidth: "0" };
const le = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w,
  {
    ref: t,
    className: _(
      "flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground",
      e
    ),
    ...n
  }
));
le.displayName = w.displayName;
const dt = ({ children: e, ...n }) => /* @__PURE__ */ y.jsx(pe, { ...n, children: /* @__PURE__ */ y.jsx(pe.Content, { className: "overflow-hidden p-0 shadow-lg", children: /* @__PURE__ */ y.jsx(le, { className: "[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5", children: e }) }) }), Re = l.forwardRef(({ className: e, onClear: n, ...t }, s) => /* @__PURE__ */ y.jsxs("div", { className: "flex items-center w-full", "cmdk-input-wrapper": "", children: [
  /* @__PURE__ */ y.jsx(
    w.Input,
    {
      ref: s,
      className: _(
        "flex h-8 w-full bg-transparent py-1 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
        e
      ),
      ...t
    }
  ),
  n && /* @__PURE__ */ y.jsx(
    Ke,
    {
      size: "sm",
      onClick: () => n(),
      className: "px-1",
      variant: "ghost",
      children: /* @__PURE__ */ y.jsx(Fe, { name: "Cancel", width: 18, height: 18, strokeWidth: 1.5 })
    }
  )
] }));
Re.displayName = w.Input.displayName;
const Ce = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w.List,
  {
    ref: t,
    className: _("max-h-[300px] overflow-y-auto overflow-x-hidden", e),
    ...n
  }
));
Ce.displayName = w.List.displayName;
const Ie = l.forwardRef((e, n) => /* @__PURE__ */ y.jsx(
  w.Empty,
  {
    ref: n,
    className: "py-6 text-center text-sm",
    ...e
  }
));
Ie.displayName = w.Empty.displayName;
const $e = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w.Group,
  {
    ref: t,
    className: _(
      "overflow-hidden py-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-sm [&_[cmdk-group-heading]]:font-normal [&_[cmdk-group-heading]]:text-black-primary",
      e
    ),
    ...n
  }
));
$e.displayName = w.Group.displayName;
const Ne = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w.Separator,
  {
    ref: t,
    className: _("-mx-1 h-px bg-border", e),
    ...n
  }
));
Ne.displayName = w.Separator.displayName;
const je = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w.Item,
  {
    ref: t,
    className: _(
      "relative flex cursor-default select-none items-center rounded-md min-h-9 px-2 py-2 text-sm outline-none aria-selected:bg-gray-neutral-30 aria-selected:text-accent-foreground",
      e
    ),
    ...n
  }
));
je.displayName = w.Item.displayName;
const Ae = ({
  className: e,
  ...n
}) => /* @__PURE__ */ y.jsx(
  "span",
  {
    className: _(
      "ml-auto text-xs tracking-widest text-muted-foreground",
      e
    ),
    ...n
  }
);
Ae.displayName = "CommandShortcut";
const gt = Object.assign(le, {
  Dialog: dt,
  Input: Re,
  List: Ce,
  Empty: Ie,
  Group: $e,
  Separator: Ne,
  Item: je,
  Shortcut: Ae
});
export {
  gt as default
};
