import { j as t } from "./jsx-runtime-D0AQ1Mn7.js";
import { forwardRef as p } from "react";
import { I as a } from "./index-BijjiL52.js";
import "./Accordion-DcmbANja.js";
import "./Avatar-al9EpjB_.js";
import "./Badge-QBVLNL8O.js";
import "./Button-CAmI0_H8.js";
import "./DropdownMenu-8pjW7QUn.js";
import "./Icon-vWfhOtJ0.js";
import { I as i } from "./Input-CyIITBNc.js";
import "./Label-C4UDFZ-F.js";
import "./Text-CwTWgi3Y.js";
import "./Tooltip-B25WPLZY.js";
import "./Stack-B4QZt_R0.js";
import "./Switch-DSz0C0fA.js";
import "./Toast-CLY2YdmW.js";
import "./Toggle-D-drA526.js";
const e = p(
  (m, o) => /* @__PURE__ */ t.jsx(a, { ...m, inputRef: o, mask: "999-999-9999", maskChar: "_", children: (r) => /* @__PURE__ */ t.jsx(
    i,
    {
      wrapClassName: r.wrapClassName,
      ...r,
      disabled: m.disabled,
      type: "tel",
      placeholder: "000-000-0000"
    }
  ) })
);
e.displayName = "PhoneNumberInput";
export {
  e as default
};
